import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../auth.service';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {StateService} from '../state.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() venueMenu = true;

  constructor(public auth: AuthService,
              public api: ApiService,
              public router: Router,
              public state: StateService) { }

  ngOnInit() {
  }

  logout() {
      this.api.logout().subscribe(
          data => {
              if (data['status'] === 'ok') {
                  this.auth.logout();
                  this.router.navigate(['/login']);
              }
          }
      );
  }

}

