import { Image } from './image.model';

export class Venue {
    public id: number;
    public code: string;
    public activation_pro_date: string;
    public status: string;

    public name: string;
    public region_id: number;
    public region: string; /* Region / Area */
    public user_id: number;
    public location_id: number;
    public location: string; /* Where / Dove */
    public type_id: number;
    public type: string; /* Type / Tipo */
    public address: string;
    public phone: string;
    public web: string;
    public email: string;

    public ceremony: number; /* Licensed for civil marriage / Cerimonia civile in loco */
    public church: number; /* Church on premises / Chiesa in loco */
    public guests: number;  /* No. of guests for the ceremony / No. ospiti spazio Cerimonia */
    public recguests: number; /* No.  of guests wedding reception / No. ospiti spazio ricevimento */
    public accommodation: number; /* Guest accommodation / Accommodation ospiti */
    public beds: number; /* No. beds / No. posti letto */
    public min_days: number; /* Minimum days / Soggiorno min richiesto */
    public min_days_exclusive: number; /* Exclusive if more than minimum / Esclusiva in caso di soggiorno min */
    public fee: number; /* Wedding day fee */
    public catering: number; /* Caterer, in-house  / Catering interno */
    public gourmet_restaurant: number; /* Gourmet restaurant / Ristorante gourmet */
    public bridecatering: number; /* Caterer: couple's choice / Catering a scelta degli sposi */
    public midnight: string; /* Late license / Orario dopo la mezzanotte */
    public holidayvilla: number; /* Holiday Villa / Villa per vacanze */
    public weekely_rental: number; /* Weekly Rental availability / Affitto settimanale disponibile o no */
    public min_price: string; /* Minimum price / Prezzo minimo */
    public review: string;
    public images: Image[];

    constructor() {
        this.images = new Array();
    }
}
