import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Route, Router} from '@angular/router';
import { ApiService } from '../api.service';
import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';
import {AuthService} from "../auth.service";

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.sass']
})
export class ActivateComponent implements OnInit {

  constructor(public api: ApiService,
              public route: ActivatedRoute,
              public dialog: MatDialog,
              public router: Router,
              public auth: AuthService
  ) {
      const code  = this.route.snapshot.params['code'];
      this.api.activate(code).subscribe(
          data => {
              if (data['status'] === 'ok') {

                if (data['token']) {
                  localStorage.setItem('jwt_access_token', data['token']);
                }

                const dRef = this.dialog.open(AlertDialogComponent, {
                  width: '350px',
                  data: { message: 'Il tuo account è stato attivato con successo, ' +
                      'ora puoi effettuare il login e iniziare la compilazione dei ' +
                      'dati della tua Venue', title: "Attivazione confermata" }
                });

                dRef.afterClosed().subscribe(result => {
                  this.router.navigate(['/login']);
                });
              }
          }
      );
  }

  ngOnInit() {
  }

}
