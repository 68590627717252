import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  public firstName;
  public lastName;

  constructor(public auth: AuthService, public router: Router) {
    
  }

  ngOnInit() {
    this.firstName = this.auth.getName();
    this.lastName = this.auth.getSurname();
  }

}
