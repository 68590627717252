import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {ApiService} from '../api.service';
import {Stat} from '../_models/stat.model';
import {Venue} from '../_models/venue.model';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  public pageIndex = 1;
  public matSortActive = 'name';
  public matSortDirection = 'asc';
  public filterValue = {text: '', year: '', month: ''};

  displayedColumns: string[] = [
      'name', 'month', 'year', 'view', 'toggle_quick_view',
      'click_www', 'click_email',
      'open_contact_venue', 'open_contact_team',
  ];

  public jsondata;
  public dataSource: MatTableDataSource<Stat>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public auth: AuthService,
              public router: Router,
              public route: ActivatedRoute,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              public api: ApiService) {
    if (!auth.isLoggedIn()) {
      this.router.navigate(['/login']);
    }

    if (!auth.isUserAdmin()) {
      this.router.navigate(['/']);
    }

    this.api.getStatistics().subscribe(
        data => {

          let text = '';

          this.jsondata = data;
          this.dataSource = new MatTableDataSource(this.jsondata);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;

          this.dataSource.filterPredicate = (stat: Stat, filterValue: string) => {

            text = this.filterValue.text.trim().toLocaleLowerCase();
            if (text !== '' && stat.name.trim().toLocaleLowerCase().indexOf(text) === -1) {
                return false;
            }

            if (this.filterValue.month !== '' && stat.month + '' !== this.filterValue.month) {
               return false;
            }

            if (this.filterValue.year !== '' && stat.year + '' !== this.filterValue.year) {
              return false;
            }

            return true;

          };

          if (localStorage.getItem('statistics-filter')) {
            this.filterValue = JSON.parse(localStorage.getItem('statistics-filter'));
            this.applyFilter(this.filterValue.text);
          }
        }
    );


    if (localStorage.getItem('statistics-page')) {
      this.pageIndex =  parseInt(localStorage.getItem('statistics-page'), 10);
    }

    if (localStorage.getItem('statistics-sort-active')) {
      this.matSortActive = localStorage.getItem('statistics-sort-active');
    }

    if (localStorage.getItem('statistics-sort-direction')) {
      this.matSortDirection =  localStorage.getItem('statistics-sort-direction');
    }

  }

  onPaginateChange(event) {
    localStorage.setItem('statistics-page', '' + event.pageIndex);
  }

  sortData(event) {
    localStorage.setItem('statistics-sort-active', event.active);
    localStorage.setItem('statistics-sort-direction', event.direction);
    this.matSortActive = event.active;
    this.matSortDirection = event.direction;
  }

  applyFilter(filterValue: string) {
    if (!filterValue) {
      filterValue = '';
    }
    this.filterValue.text = filterValue;
    localStorage.setItem('statistics-filter', JSON.stringify(this.filterValue));
    this.dataSource.filter = JSON.stringify(this.filterValue);
  }

  applyFilterMonth(filterValue: string) {
    this.filterValue.month = filterValue;
    localStorage.setItem('statistics-filter', JSON.stringify(this.filterValue));
    this.dataSource.filter = JSON.stringify(this.filterValue);
  }

  applyFilterYear(filterValue: string) {
    this.filterValue.year = filterValue;
    localStorage.setItem('statistics-filter', JSON.stringify(this.filterValue));
    this.dataSource.filter = JSON.stringify(this.filterValue);
  }

  resetFilters() {
    this.filterValue = {
      text: '',
      month: '',
      year: ''
    };
    this.dataSource.filter = JSON.stringify(this.filterValue);
  }

  ngOnInit() {
    const id  = this.route.snapshot.params['id'];
    this.api.getVenue(id).subscribe(
        data => {
           this.filterValue.text = data['name'];
           this.applyFilter(data['name']);
        }
    );
  }

}
