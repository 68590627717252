import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';

import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { Venue } from '../_models/venue.model';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss']
})
export class VenuesComponent implements OnInit {
  displayedColumns: string[] = ['status', 'name', 'region', 'code', 'email', 'actions'];

  public jsondata;
  public dataSource: MatTableDataSource<Venue>;

  @ViewChild('table') table: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public pageIndex = 1;
  public matSortActive = 'name';
  public matSortDirection = 'asc';
  public filterValue = {text: '', status: ''}

  constructor(public auth: AuthService,
              public router: Router,
              public route: ActivatedRoute,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              public api: ApiService) {
    if (!auth.isLoggedIn()) {
      this.router.navigate(['/login']);
    }

    if (!auth.isUserAdmin()) {
      this.router.navigate(['/']);
    }

    this.api.getVenues().subscribe(
        data => {
          this.jsondata = data['data'];
          this.dataSource = new MatTableDataSource(this.jsondata);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;

          this.dataSource.filterPredicate = (venue: Venue, filterValue: string) => {

              if (venue.name.trim().toLocaleLowerCase().indexOf(this.filterValue.text) > -1 ||
                  venue.region.trim().toLocaleLowerCase().indexOf(this.filterValue.text) > -1 ||
                  venue.code.trim().toLocaleLowerCase().indexOf(this.filterValue.text) > -1
              ) {

                  if (this.filterValue.status) {
                      if (venue.status.trim().toLocaleLowerCase() === this.filterValue.status) {
                          return true;
                      } else {
                         return false;
                      }
                  }

                  return true;
              }

          };

          if (localStorage.getItem('venues-filter')) {
              this.filterValue = JSON.parse(localStorage.getItem('venues-filter'));
              this.applyFilter(this.filterValue.text);
          }
        }
    );


    if (localStorage.getItem('venues-page')) {
        this.pageIndex =  parseInt(localStorage.getItem('venues-page'), 10);
        this.matSortActive =  localStorage.getItem('venues-sort-active');
        this.matSortDirection =  localStorage.getItem('venues-sort-direction');
    }

  }

  ngOnInit() {
    //this.dataSource.sort = this.sort;
    //this.dataSource.paginator = this.paginator;
  }

  venueStats(element) {
      this.router.navigate(['/statistics/' + element.id]);
  }

  editVenue(element) {
      this.router.navigate(['/venues/edit/' + element.id]);
  }

  onPaginateChange(event) {
      localStorage.setItem('venues-page', '' + event.pageIndex);
  }

  sortData(event) {
      localStorage.setItem('venues-sort-active', event.active);
      localStorage.setItem('venues-sort-direction', event.direction);
      this.matSortActive = event.active;
      this.matSortDirection = event.direction;
  }

  venueConfirm(element): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Sei sicura di voler confermare la venue?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.api.confirmVenue(element.id).subscribe(
            data => {
              if (data['status'] === 'ok') {
                 element.status = 'ok';
              } else {
              }
            }
        );

      } else {
        return false;
      }
    });
  }

  venueReject(element): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Sei sicura di voler rifiutare la venue?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.api.rejectVenue(element.id).subscribe(
            data => {
              if (data['status'] === 'ok') {
                element.status = 'ko';
              } else {
              }
            }
        );
      } else {
        return false;
      }
    });
  }

  venuePro(element): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Sei sicura di voler passare la venue a PRO?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.api.proVenue(element.id).subscribe(
            data => {
              if (data['status'] === 'ok') {
                element.status = 'ok_pro';
              } else {
              }
            }
        );
      } else {
        return false;
      }
    });
  }

  onDelete(element) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Sei sicura di voler cancellare la venue?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.api.deleteVenue(element.id).subscribe(
            data => {

              if (data['status'] === 'ok') {
                var self = this;
                this.jsondata.forEach(
                    function(e,i) {
                      if (e.id == element.id) {
                        self.jsondata.splice(i, 1);
                      }
                    }
                );
                this.dataSource.data = this.jsondata;
              } else {
              }
            }
        );
      } else {
        return false;
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    this.filterValue.text = filterValue;
    localStorage.setItem('venues-filter', JSON.stringify(this.filterValue));
    this.dataSource.filter = JSON.stringify(this.filterValue);
  }

  applyFilter2(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    this.filterValue.status = filterValue;
    localStorage.setItem('venues-filter', JSON.stringify(this.filterValue));
    this.dataSource.filter = JSON.stringify(this.filterValue);
    console.log(this.dataSource);
  }

  onExport() {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.dataSource.filteredData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'export_venues');

    XLSX.writeFile(wb, 'export_venues_' + this.filterValue.status + '_' + this.filterValue.text + '.xlsx');
  }
}

export interface Venues {
  id: number;
  name: string;
  region: string;
  type: string;
  location: string;
  company: string;
  code: string;
  email: string;
  status: string;
  pro: boolean;
}
