import { Component } from '@angular/core';
import {AlertDialogComponent} from './shared/alert-dialog/alert-dialog.component';
import {MatDialog} from '@angular/material';
import {StateService} from './state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public title = 'V-Atelier';
  public connAlert = false;

  public constructor(public dialog: MatDialog,
                     public state: StateService) {

      if (this.state.getConnError()) {
        this.state.unsetConnError();
        const dRef = this.dialog.open(AlertDialogComponent, {
          width: '350px',
          data: 'Errore di rete, controllare la propria connessione e riprovare'
        });
      }
  }
}
