import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Injectable} from '@angular/core';
import {AuthService} from '../auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    public  constructor(public router: Router, public auth: AuthService) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (!this.auth.isLoggedIn()) {
            this.router.navigate(['/login']);
            return false;
        }

        if (state.url === '/new-venue' && !this.auth.isUserAdmin()) {
            this.router.navigate(['/login']);
            return false;
        }

        return true;

    }

}