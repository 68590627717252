import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { User } from '../_models/user.model';
import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  @ViewChild('f') formValues;

  public user: User;
  public errors: object;
  public recaptchaOk = false;

  constructor(public api: ApiService,
              public auth: AuthService,
              public router: Router,
              public route: ActivatedRoute,
              public dialog: MatDialog) {
    if (auth.isLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.user = new User();
    this.cleanErrors();
  }

  onSubmit(form: NgForm) {

    if (!this.recaptchaOk) {
        this.errors['recaptcha'] = 'Recaptcha non valido';
        //console.log('recaptcha ko');
        return;
    }

    this.api.signUp(this.user).subscribe(

        data => {
            if (data['status'] === 'ok') {

                const dialogRef = this.dialog.open(AlertDialogComponent, {
                    width: '350px',
                    data: { message:  'Registrazione avvenuta con successo, riceverai un\'email per attivare il tuo account.', title:'Conferma Registrazione' }
                });

                dialogRef.afterClosed().subscribe(result => {
                    this.router.navigate(['/login']);
                });

            }
        },

        err => {
            this.cleanErrors();
            if (err.errors) {
                Object.keys(err.errors).forEach(
                    k => {
                        this.errors[k] = err.errors[k][0];
                    }
                );
            }
        }
    );
  }

  cleanErrors() {
      this.errors = new Object();
      Object.keys(this.user).forEach(
          k => {
              this.errors[k] = '';
          }
      );
  }


  resolved(captchaResponse: string) {
    console.log('Resolved captcha with response ' + captchaResponse);
    this.recaptchaOk = true;
  }


}
