import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    private apiURL = environment.apiURL;

    constructor(private http: HttpClient) {
    }

    public venueId;

    public getApiUrl() {
        return this.apiURL;
    }

    // Login api call

    public login(username: string, password: string) {
        return this.http.post(this.apiURL + '/login', {email: username, password: password});
    }

    public logout() {
        return this.http.post(this.apiURL + '/logout', {});
    }

    // Users api calls

    public getUsers() {
        return this.http.get(this.apiURL + '/users');
    }

    public getUser(id) {
        return this.http.get(this.apiURL + '/users/' + id);
    }

    public signUp(user) {
        return this.http.post(this.apiURL + '/users', user);
    }

    public updateUser(user) {
        return this.http.put(this.apiURL + '/users', user);
    }

    public deleteUser(id) {
        return this.http.delete(this.apiURL + '/users/' + id);
    }

    public getLocations() {
        return this.http.get(this.apiURL + '/locations');
    }

    public getTypes() {
        return this.http.get(this.apiURL + '/types');
    }

    public getRegions() {
        return this.http.get(this.apiURL + '/regions');
    }

    public getVenues() {
        return this.http.get(this.apiURL + '/venues');
    }

    public getVenue(id) {
        return this.http.get(this.apiURL + '/venues/' + id);
    }

    public getStatistics() {
        return this.http.get(this.apiURL + '/statistics');
    }

    public getVenueStatistics(id) {
        return this.http.get(this.apiURL + '/venues/' + id + '/statistics');
    }

    public deleteImage(id) {
        return this.http.delete(this.apiURL + '/images/' + id);
    }

    public setUserVenueId(venueId) {
        this.venueId = venueId;
    }

    public getUserVenue() {
        return this.http.get(this.apiURL + '/users/myvenue');
    }

    public getNewVenue() {
        return this.http.post(this.apiURL + '/venues', null);
    }

    public updateVenue(venue) {
        return this.http.put(this.apiURL + '/venues', venue);
    }

    public draftVenue(venue) {
        return this.http.put(this.apiURL + '/venues-draft', venue);
    }

    public confirmVenue(id) {
        return this.http.put(this.apiURL + '/venues/confirm', {id: id});
    }

    public rejectVenue(id) {
        return this.http.put(this.apiURL + '/venues/reject', {id: id});
    }

    public proVenue(id) {
        return this.http.put(this.apiURL + '/venues/pro', {id: id});
    }

    public deleteVenue(id) {
        return this.http.delete(this.apiURL + '/venues/' + id);
    }

    // Reset password

    public resetPassword(email) {
        return this.http.post(this.apiURL + '/users/reset', {email: email});
    }

    public newPassword(password, hash) {
        return this.http.post(this.apiURL + '/users/changeuserpassword', {password: password, hash: hash});
    }

    public sendactivationlink() {
        return this.http.get(this.apiURL + '/users/sendactivationlink');
    }

    public activate(code) {
        return this.http.get(this.apiURL + '/users/activate/' + code);
    }

}
