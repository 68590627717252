import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private venueStatus: string;
  private userStatus: string;
  private connError = false;

  constructor() {
    this.venueStatus = 'draft';
  }

  public setVenueStatus(status) {
    this.venueStatus = status;
  }

  public getVenueStatus() {
    return this.venueStatus;
  }

  public setUserStatus(status) {
    this.userStatus = status;
  }

  public getuserStatus() {
    return this.userStatus;
  }

  public setConnError() {
    this.connError = true;
    console.log('true');
  }

  public unsetConnError() {
    this.connError = false;
    console.log('false');
  }

  public getConnError() {
    return this.connError;
  }
}
