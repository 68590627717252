import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes} from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatGridListModule, MatCardModule, MatIconModule,
         MatTooltipModule, MatDialogModule, MatExpansionModule,
         MatTableModule, MatSortModule, MatPaginatorModule, MatSnackBarModule,
         MatProgressBarModule, MatCheckboxModule } from '@angular/material';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import { NgxUploaderModule } from 'ngx-uploader';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { VenueComponent } from './venue/venue.component';
import { VenuesComponent } from './venues/venues.component';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './user/user.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { ConfirmComponent } from './venue/confirm/confirm.component';
import { DialogPhotoComponent } from './venue/dialog-photo/dialog-photo.component';
import { DialogProComponent } from './venues/dialog-pro/dialog-pro.component';

import { HTTP_INTERCEPTORS} from '@angular/common/http';
import { AuthInterceptorService } from './auth-interceptor.service';
import { ErrorInterceptorService } from './error-interceptor.service';
import {AuthGuard} from './_classes/AuthGuard';
import {PrivacyComponent} from './privacy/privacy.component';
import { ActivateComponent } from './activate/activate.component';

import { NgxLoadingModule } from 'ngx-loading';
import { PremiumComponent } from './premium/premium.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { QuillModule } from 'ngx-quill';
import { QuillConfig } from "ngx-quill";

const appRoutes: Routes = [
  { path: 'login',  component: LoginComponent },
  { path: 'login/:param',  component: LoginComponent },
  { path: 'signup',  component: SignupComponent },
  { path: 'venue',  component: VenueComponent, canActivate: [AuthGuard] },
  { path: 'new-venue',  component: VenueComponent, canActivate: [AuthGuard] },
  { path: 'venues',  component: VenuesComponent, canActivate: [AuthGuard] },
  { path: 'users',  component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'user/view/:id',  component: UserComponent, canActivate: [AuthGuard] },
  { path: 'user/edit/:id',  component: UserComponent, canActivate: [AuthGuard] },
  { path: 'venues/edit/:id',  component: VenueComponent, canActivate: [AuthGuard] },
  { path: 'reset',  component: ResetPasswordComponent },
  { path: 'new-password/:hash',  component: NewPasswordComponent },
  { path: 'venue-confirm',  component: ConfirmComponent, canActivate: [AuthGuard] },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'activate/:code',  component: ActivateComponent },
  { path: 'statistics',  component: StatisticsComponent, canActivate: [AuthGuard] },
  { path: 'statistics/:id',  component: StatisticsComponent, canActivate: [AuthGuard] },
  { path: '', component: HomepageComponent },
  { path: '**', component: HomepageComponent }
];
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomepageComponent,
    LoginComponent,
    SignupComponent,
    VenueComponent,
    VenuesComponent,
    UsersComponent,
    UserComponent,
    ConfirmationDialogComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    AlertDialogComponent,
    ConfirmComponent,
    DialogPhotoComponent,
    DialogProComponent,
    PrivacyComponent,
    ActivateComponent,
    PremiumComponent,
    StatisticsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    FormsModule,
    MatGridListModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatProgressBarModule,
    RecaptchaModule,
    NgxUploaderModule,
    MatCheckboxModule,
    QuillModule.forRoot(),
    NgxLoadingModule.forRoot({})
    ],
  entryComponents: [
    DialogPhotoComponent,
    DialogProComponent,
    ConfirmationDialogComponent,
    AlertDialogComponent,
  ],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
      AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
