import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';

import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { DialogPhotoComponent } from './dialog-photo/dialog-photo.component';
import { Venue } from '../_models/venue.model';
import { ApiService } from '../api.service';
import { VType } from '../_models/vtype';
import { Region } from '../_models/region';
import { Location } from '../_models/location';
import {environment} from '../../environments/environment';
import {AlertDialogComponent} from '../shared/alert-dialog/alert-dialog.component';
import {first} from 'rxjs/operators';
import {StateService} from '../state.service';


@Component({
  selector: 'app-venue',
  templateUrl: './venue.component.html',
  styleUrls: ['./venue.component.scss']
})
export class VenueComponent implements OnInit {

  toolbarConfig = [
    ['bold', 'italic', 'underline'],        // Formattazione base
    ['blockquote'],                      // Blocchi speciali
    [],               // Intestazioni
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],    // Liste
    ['link'],                                // Media
    ['clean']                                         // Pulisci formattazione
  ];

  quillModules = {
    toolbar: this.toolbarConfig
  };

  public imgURL = environment.imgURL;

  public expanded1: boolean;
  public expanded2: boolean;
  public expanded3: boolean;

  // file upload

  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  public venue: Venue;
  public errors: object;

  @ViewChild('f') formValues;
  requiredFields: number;

  public breakpoint;
  public loading = false;

  public venueStatus = [
    { label: 'In bozza', value:  'draft' },
    { label: 'In attesa di approvazione', value:  'waiting' },
    { label: 'Non approvato', value:  'ko' },
    { label: 'Pubblicato free', value: 'ok' },
    { label: 'Pubblicato pro', value: 'ok_pro' },
    { label: 'In scadenza', value: 'expiring' },
    { label: 'Best of', value: 'best_of' }
  ];

  public locations: Location[];
  public types: VType[];
  public regions: Region[];

  constructor(public auth: AuthService,
              public router: Router,
              public route: ActivatedRoute,
              public dialog: MatDialog,
              public api: ApiService,
              public state: StateService,
             ) {

    if (!auth.isLoggedIn()) {
      this.router.navigate(['/login']);
    }

    // file upload

    this.options = { concurrency: 1, maxUploads: 30 };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;

  }

  ngOnInit() {

    this.expanded1 = true;
    this.expanded2 = false;
    this.expanded3 = false;

    if (window.innerWidth <= 549) {
      this.breakpoint = 1;
    } else if (window.innerWidth >= 550 && window.innerWidth <= 1024) {
      this.breakpoint = 1;
    } else if (window.innerWidth >= 1025) {
      this.breakpoint = 1;
    }

    this.api.getLocations().subscribe(
        data => {
          this.locations = data as Location[];
        }
    );

    this.api.getTypes().subscribe(
        data => {
          this.types = data as VType[];
        }
    );

    this.api.getRegions().subscribe(
        data => {
          this.regions = data as Region[];
        }
    );

    this.venue = new Venue();

    if (!this.auth.isUserAdmin()) {
        this.api.getUserVenue().subscribe(
          data => {
            this.venue = data as Venue;
        });
    } else {

      if (this.router.url === '/new-venue') {
          this.api.getNewVenue().subscribe(
            data => {
              this.venue = data as Venue;
          });
      } else {

        const id  = this.route.snapshot.params['id'];
        this.api.getVenue(id).subscribe(
          data => {
            if (data['id'] == id) {
               this.venue = data as Venue;
            } else {

            }
          }
        );
      }
    }

    this.cleanErrors();

  }

  openInfoDialog(): void {
    const dialogRef = this.dialog.open(DialogPhotoComponent, {});
  }

  onResize(event) {
    if (event.target.innerWidth <= 549) {
      this.breakpoint = 1;
    } else if (event.target.innerWidth >= 550 && event.target.innerWidth <= 1024) {
      this.breakpoint = 1;
    } else if (event.target.innerWidth >= 1025) {
      this.breakpoint = 1;
    }
  }

  cleanErrors() {
    setTimeout(() => {this.loading = false; }, 500);
    this.errors = new Object();
    Object.keys(this.venue).forEach(
        k => {
          this.errors[k] = '';
        }
    );
  }

  onSubmitRequest(f) {

    let dialogRef;

    if (!this.auth.isUserAdmin()) {

      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: 'Sei sicuro di voler inviare i dati?\nUna volta inviati non sarà possibile modificarli.'
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.onSubmit(f);
        } else {
          return false;
        }
      });

    } else {
      this.onSubmit(f);
    }

  }

  onSubmit(form: NgForm) {

    const endpoint = 'updateVenue';
    this.loading = true;

    this.api[endpoint](this.venue).subscribe(
        data => {
          if (data['status'] === 'ok') {

            this.cleanErrors();

            if (this.auth.isUserAdmin()) {
              this.router.navigate(['/venues']);
              return;
            }

            const dRef = this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: {
                title: 'Invio confermato',
                message : 'Grazie per aver inserito la tua location nel nostro V-Atelier! ' +
                  '  Il nostro Team  sta verificando i tuoi dati e presto riceverai una Email di conferma'}
            });
            this.state.setVenueStatus('waiting');
            this.venue.status = 'waiting';

            dRef.afterClosed().subscribe(
                result => {
                  this.router.navigate(['/venue']);
                }
            );

          } else {
            this.loading = false;
            this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: { message: data['message'] }
            });
          }
        },

        err => {

          this.cleanErrors();

          if (err.status === 0) {

            const dRef = this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: {message: 'Errore di rete, controllare la propria connessione e riprovare'}
            });

            return;
          }

          if (err.errors) {
            this.expanded1 = true;
            this.expanded2 = true;
            this.expanded3 = true;

            let firstError = '';
            Object.keys(err.errors).forEach(
                k => {
                  this.errors[k] = err.errors[k][0];
                  if (!firstError && err.errors[k][0] !== '') {
                    firstError = k;
                  }
                }
            );

            const dRef = this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: {message: 'Ci sono degli errori nel modulo, si prega di correggerli e inviare nuovamente'}
            });

            dRef.afterClosed().subscribe(
                result => {
                  this.scroll(firstError);
                }
            );

          }
        }
    );
  }


  onDraft(form: NgForm) {

    const endpoint = 'draftVenue';
    this.loading = true;

    this.api[endpoint](this.venue).subscribe(
        data => {
          if (data['status'] === 'ok') {
            this.cleanErrors();
            const dRef = this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: { message:  data['message'], title:'Congratulazioni' }
            });
          } else {
            this.loading = false;
            this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: { message: data['message']  }
            });
          }
        },

        err => {

          this.cleanErrors();

          if (err.status === 0) {

            const dRef = this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: {message: 'Errore di rete, controllare la propria connessione e riprovare'}
            });

            return;
          }

          if (err.errors) {

            this.expanded1 = true;
            this.expanded2 = true;
            this.expanded3 = true;

            let firstError = '';
            Object.keys(err.errors).forEach(
                k => {
                  this.errors[k] = err.errors[k][0];
                  if (!firstError && err.errors[k][0] !== '') {
                    firstError = k;
                  }
                }
            );

            const dRef = this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: {message:'Ci sono degli errori nel modulo, si prega di correggerli e inviare nuovamente'}
            });

            dRef.afterClosed().subscribe(
                result => {
                  this.scroll(firstError);
                }
            );

          }
        }
    );
  }

  onCancel(form: NgForm) {
    if (form.dirty === true) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: {message: 'Hai fatto delle modifiche, sei sicuro di voler tornare indietro? Così facendo perderai tutte le modifiche'}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/home']);
        } else {
          return false;
        }
      });
    } else {
      this.router.navigate(['/home']);
    }
  }

  // file upload

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {

      case 'allAddedToQueue':
        console.log("upload files when added, aggiungere funzione se serve");
        break;

      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
        }
        break;

      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;

      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;

      case 'dragOver':
        this.dragOver = true;
        break;

      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;

      case 'done':

        if (output.file.responseStatus === 422) {
           let imageError = '';
           imageError += output.file.name + ' : ';
           imageError += output.file.response.errors.file[0];

           this.removeFile(output.file.id);

           this.dialog.open(AlertDialogComponent, {
             width: '650px',
             data: imageError
           });

        } else {

          if (this.auth.isUserAdmin()) {
            this.api.getVenue(this.venue.id).subscribe(
                data => {
                  this.venue.images = data['images'];
                }
            );
          } else {

            this.api.getUserVenue().subscribe(
                data => {
                  this.venue.images = data['images'];
                }
            );
          }
          this.cleanErrors();
        }

        break;
    }
  }

  startUpload(): void {

    const event: UploadInput = {
      type: 'uploadAll',
      url: this.api.getApiUrl() + '/venues/upload',
      method: 'POST',
      data: { venue_id: '' + this.venue.id },
      headers: {
        Authorization: 'Bearer ' + this.auth.getToken(),
        Accept: 'application/json'
      },
    };

    this.uploadInput.emit(event);
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  onDelete(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Si vuole eliminare la foto?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteImage(id);
      } else {
        return false;
      }
    });
  }

  public deleteImage(id) {
      this.api.deleteImage(id).subscribe(
          data => {
            if (data['status'] === 'ok') {
               const images = this.venue.images.filter(
                   element => {
                     if (element.id === id) {
                       return false;
                     } else {
                       return true;
                     }
                   }
               );

               this.venue.images = images;
            }
          }
      );
  }


  scroll(id) {
    let el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({behavior: 'smooth'});
    }
  }


  sendActivationLink() {
    this.api.sendactivationlink().subscribe(
      data => {

        if (data['status'] === 'ok') {
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '350px',
            data: {message: data['message'], title: "Attiva il tuo account"}
          });
        }

        if (data['status'] === 'ko') {
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '350px',
            data: {message: data['message']}
          });
        }

      }
    );
  }

}
