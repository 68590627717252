import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import {ApiService} from '../api.service';
import {AlertDialogComponent} from '../shared/alert-dialog/alert-dialog.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public email: string;

  constructor(public auth: AuthService,
              public router: Router,
              public api: ApiService,
              public dialog: MatDialog) {
    if (auth.isLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {

    this.api.resetPassword(this.email).subscribe(
        data => {

          if (data['status'] === 'ko') {
            this.email = '';
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: data['message']
            });
          }

          if (data['status'] === 'ok') {
            this.email = '';
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: 'È stata inviata all\'indirizzo fornito un\'email con il link per resettare la password'
            });

            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(['/login']);
            });
          }

        }
    );

  }

}
