export class User {
    public id: number;
    public name: string;
    public surname: string;
    public email: string;
    public phone: string;
    public company: string;
    public address: string;
    public vat_id: string;
    public sdi: string;
    public pec: string;
    public signupdate: Date;
    public role: string;
    public password: string;
    public confirm_password: string;
    public privacy: boolean;
    public activated: string;
}
