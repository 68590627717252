import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../_models/user.model';
import { NgForm } from '@angular/forms';

import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';

import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public user: User;
  public errors: object;

  constructor(public api: ApiService,
              public auth: AuthService,
              public router: Router,
              public route: ActivatedRoute,
              public dialog: MatDialog) {
    if (!auth.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.user = new User();
    const id = this.route.snapshot.paramMap.get('id');
    this.api.getUser(id).subscribe(
      data => {
          this.user = data['data'];
      }
    );
    this.cleanErrors();
    console.log(this.user, id);
  }

  onSubmit(form: NgForm) {
    this.api.updateUser(this.user).subscribe(

        data => {
          console.log('data');
          if (data['status'] === 'ok') {
             this.cleanErrors();
             this.router.navigate(['/users']);
          } else {
            alert('ko');
          }
        },

        err => {
          this.cleanErrors();
          if (err.errors) {
            Object.keys(err.errors).forEach(
                k => {
                  this.errors[k] = err.errors[k][0];
                }
            );
          }
        }
    );
  }

  onBack(form: NgForm) {
    if (form.dirty === true) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: "Hai fatto delle modifiche, sei sicuro di voler tornare indietro? Così facendo perderai tutte le modifiche"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/users']);
        } else {
          return false;
        }
      });
    } else {
      this.router.navigate(['/users']);
    }
  }

  cleanErrors() {
    this.errors = new Object();
    Object.keys(this.user).forEach(
        k => {
          this.errors[k] = '';
        }
    );
  }

}
