import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {StateService} from '../state.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {MatDialog} from '@angular/material';
import {AlertDialogComponent} from '../shared/alert-dialog/alert-dialog.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public mex = '';

  @ViewChild('f') formValues;

  private helper = new JwtHelperService();
  public username: string;
  public password: string;

  constructor(public api: ApiService,
              public auth: AuthService,
              public router: Router,
              public route: ActivatedRoute,
              public state: StateService,
              public dialog: MatDialog) {

      if (this.route.snapshot.paramMap.get('param') === 'resetok') {
          this.mex = 'La nuova password è stata impostata';
          const dialogRef = this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: this.mex
          });
      }

      if (this.route.snapshot.paramMap.get('param') === 'resetko') {
          this.mex = 'Il link per il recupero password non è più valido, richiedere un nuovo recupero';
          const dialogRef = this.dialog.open(AlertDialogComponent, {
              width: '350px',
              data: this.mex
          });
      }

      if (auth.isLoggedIn()) {
          if (auth.isUserAdmin()) {
              this.router.navigate(['/venues']);
          } else {
              this.router.navigate(['/venue']);
          }
      }

  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {

    const username = form.value.username;
    const password = form.value.password;

    this.api.login(username, password).subscribe(

        result => {

            if ('access_token' in result) {
                localStorage.setItem('jwt_access_token', result['access_token']);
            }

            if ('expires_in' in result) {
                localStorage.setItem('jwt_expires_in', result['expires_in']);
            }

            if ('token_type' in result) {
                localStorage.setItem('jwt_token_type', result['token_type']);
            }

            const token = this.helper.decodeToken(localStorage.getItem('jwt_access_token'));

            this.state.setVenueStatus(token['venue_status']);
            this.state.setUserStatus(token['activated']);

            if (this.auth.isLoggedIn()) {
                if (this.auth.isUserAdmin()) {
                    this.router.navigate(['/venues']);
                    return;
                } else {
                    this.router.navigate(['/venue']);
                    return;
                }
            }


        },

        error => {
          if (error === 'Unauthorized' || error.statusText === 'Unauthorized') {
              this.formValues.resetForm();
              alert('Login errato');
          } else {
            alert('Login errato');
            //alert('Si è verificato un errore inatteso, provare a ricaricare la pagina');
          }

        }
    );

  }
}
