import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {StateService} from './state.service';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

    constructor(private auth: AuthService,
                public state: StateService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // return next.handle(request);

        return next.handle(request).pipe(catchError(err => {

            // Authorization errors

            if (err.status === 401) {

                // auto logout if 401 response returned from api

                if (request.url.indexOf('api/login') > -1) {
                    this.auth.logout();
                } else {
                    this.auth.logout();
                    location.href = '/';
                    return;
                }
            }

            // Validation errors

            if (err.status === 422) {
                return throwError(err.error);
            }

            if (err.status === 0) {
                this.state.setConnError();
                return throwError(err);
            }

            const error = err.error.message || err.statusText;
            return throwError(err);
        }));
    }
}
