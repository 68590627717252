import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private helper = new JwtHelperService();
    private venueId;

    constructor(public api: ApiService) {
    }

    getName() {
        if (this.isLoggedIn()) {
            const token = this.helper.decodeToken(localStorage.getItem('jwt_access_token'));
            if (token['name']) {
                return token['name'];
            }
        }
        return '';
    }

    getSurname() {
        if (this.isLoggedIn()) {
            const token = this.helper.decodeToken(localStorage.getItem('jwt_access_token'));
            if (token['surname']) {
                return token['surname'];
            }
        }
        return '';
    }

    isLoggedIn() {

        var token;

        if (!localStorage.getItem('jwt_access_token')) {
            return false;
        } else {
            token = localStorage.getItem('jwt_access_token');
        }

        if (this.helper.isTokenExpired(token)) {
            return false;
        }

        return true;
    }

    isActivated() {
        if (this.isLoggedIn()) {
            const token = this.helper.decodeToken(localStorage.getItem('jwt_access_token'));
            //if (token['activated'] === 'yes') {
                return true;
            //} else {
            //    return false;
            //}
        }
        return false;
    }


    getToken() {
        if (localStorage.getItem('jwt_access_token')) {
            return localStorage.getItem('jwt_access_token');
        }
    }

    logout() {
        localStorage.removeItem('jwt_access_token');
        localStorage.removeItem('jwt_expires_in');
        localStorage.removeItem('jwt_token_type');
    }

    isUserAdmin() {
        if (this.isLoggedIn()) {
            const token = this.helper.decodeToken(localStorage.getItem('jwt_access_token'));
            if (token['role'] === 'admin') {
                return true;
            }
        }
        return false;
    }

}
