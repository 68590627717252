import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import { NgForm } from '@angular/forms';

import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  public hash: string;
  public password1: string;
  public password2: string;

  constructor(public api: ApiService, public auth: AuthService, public router: Router,
              public route: ActivatedRoute,
              public dialog: MatDialog) {
    if (auth.isLoggedIn()) {
      this.router.navigate(['/login']);
    }

    this.hash = this.route.snapshot.paramMap.get('hash');
  }

  ngOnInit() {
  }


  onSubmit(form: NgForm) {

    if (this.password1 !== this.password2) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '350px',
        data: 'Le password non coincidono'
      });
      this.password1 = '';
      this.password2 = '';
    } else if (this.password1.length < 8) {

      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '350px',
        data: 'La password deve essere lunga almeno 8 caratteri'
      });

    } else {
      this.api.newPassword(this.password1, this.hash).subscribe(
          data => {
            if (data['status'] === 'ok') {
              this.router.navigate(['/login/resetok']);
            } else {
              this.router.navigate(['/login/resetko']);
              const dialogRef = this.dialog.open(AlertDialogComponent, {
                width: '350px',
                data: data['message']
              });
            }
          }
      );
    }
  }

}
