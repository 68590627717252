import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';

import { User } from '../_models/user.model';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import {ApiService} from '../api.service';

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = ['name', 'surname', 'phone', 'email', 'venuename', 'signupdate', 'actions'];
  public jsondata;
  public dataSource: MatTableDataSource<User>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public auth: AuthService,
              public router: Router,
              public route: ActivatedRoute,
              public http: HttpClient,
              public dialog: MatDialog,
              public api: ApiService) {

    if (!auth.isLoggedIn()) {
      this.router.navigate(['/login']);
    }

    if (!auth.isUserAdmin()) {
      this.router.navigate(['/']);
    }

    this.api.getUsers().subscribe(
      data => {
        this.jsondata = data['data'];
        this.dataSource = new MatTableDataSource(this.jsondata);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    );
  }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    console.log(this.dataSource);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onDelete(element) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Sei sicura di voler cancellare questo utente?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.api.deleteUser(element.id).subscribe(
          data => {
            if (data['status'] == 'ok') {

              var self = this;
              this.jsondata.forEach(
                  function(e,i) {
                    if (e.id == element.id) {
                      self.jsondata.splice(i, 1);
                    }
                  }
              );
              this.dataSource.data = this.jsondata;

              //alert('ok');

            } else {
              //alert(data['message']);
            }
          }
        );
      } else {
        return false;
      }
    });
  }

  onExport() {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.dataSource.filteredData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'export_users');

    XLSX.writeFile(wb, 'export_users.xlsx');
  }

}
